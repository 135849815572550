<template>
  <div>
    <v-card elevation="2" outlined shaped tile>
      <v-card-title>{{ $t("brands.brands") }}</v-card-title>

      <v-dialog v-if="selected" scrollable v-model="showEdit" max-width="700px">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <picture-input
                      :crop="false"
                      class="my-picture-input"
                      ref="pictureInput"
                      width="500"
                      height="300"
                      size="5"
                      :prefill="
                        getImageUri(
                          selected._extended.static
                            ? selected._extended.static
                            : null
                        )
                      "
                      accept="image/jpeg,image/png"
                      button-class="btn btn-outline-info"
                      aspect="Landscape"
                      :custom-strings="{
                        upload: this.$t('uploading_image'),
                        change: this.$t('change'),
                        drag: this.$t('brand_photo')
                      }"
                      @change="onPicture"
                    >
                    </picture-input>
                    <small style="text-align: center; font-size: 11px; "
                      >5MB max.</small
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.name"
                      v-bind:label="$t('brands.name')"
                      required
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.domainId"
                      v-bind:label="`Nombre de dominio`"
                      :items="domainNamesList"
                      item-text="name"
                      item-value="id"
                      filled
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      v-model="selected.description"
                      v-bind:label="$t('brands.description')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-menu
                      ref="startDate_menu"
                      v-model="select_startDate_menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="selected.startDate"
                          v-bind:label="$t('brands.start_date')"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          required
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="selected.startDate"
                        no-title
                        @input="select_startDate_menu = false"
                        :first-day-of-week="1"
                        :locale="this.$i18n.locale"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-menu
                      ref="endDate_menu"
                      v-model="select_endDate_menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="selected.endDate"
                          v-bind:label="$t('brands.end_date')"
                          prepend-icon="mdi-calendar"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                          required
                          :rules="requiredRules"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="selected.endDate"
                        no-title
                        scrollable
                        @input="select_endDate_menu = false"
                        :first-day-of-week="1"
                        :locale="this.$i18n.locale"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.module_info"
                      v-bind:label="
                        $t('brands.module') + ' ' + $t('brands.Info')
                      "
                      :items="yn_type"
                      filled
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.module_sales"
                      v-bind:label="
                        $t('brands.module') + ' ' + $t('brands.Sales')
                      "
                      :items="yn_type"
                      filled
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.module_actions"
                      v-bind:label="
                        $t('brands.module') + ' ' + $t('brands.Actions')
                      "
                      :items="yn_type"
                      filled
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.platform_photos"
                      v-bind:label="
                        $t('brands.platform') + ' ' + $t('brands.Photos')
                      "
                      :items="yn_type"
                      filled
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.platform_reporting"
                      v-bind:label="
                        $t('brands.platform') + ' ' + $t('brands.Reporting')
                      "
                      :items="yn_type"
                      filled
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.platform_training"
                      v-bind:label="
                        $t('brands.platform') + ' ' + $t('brands.Training')
                      "
                      :items="yn_type"
                      filled
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    class="d-flex justify-center"
                  >
                    <v-radio-group
                      v-model="selected.status"
                      row
                      required
                      :rules="requiredRules"
                    >
                      <v-radio label="Active" value="active"></v-radio>
                      <v-radio label="Inactive" value="inactive"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeEditDialog">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn color="blue darken-1" text @click="onSaveClick(selected)">
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h6">{{
            $t("brands.confirmdelete")
          }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">{{
              $t("cancel")
            }}</v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn color="primary" dark class="mb-2" @click="onItemEdit">
              {{ $t("newitem") }}
            </v-btn>
          </v-col>
        </v-row>
        <ve-table
          id="loading-container"
          ref="tableRef"
          fixed-header
          max-height="calc(100vh - 330px)"
          :columns="columns"
          :table-data="brands"
          :border-around="true"
          :border-x="true"
          :border-y="true"
          :scroll-width="1500"
          :sort-option="sortOption"
          :cell-style-option="cellStyleOption"
        />
        <div v-show="showEmpty" class="empty-data">Data Empty.</div>
        <div class="table-pagination mt-2 text-right">
          <ve-pagination
            :total="totalbrands"
            :page-index="pageIndex"
            :page-size="pageSize"
            :page-size-option="pageOptions"
            @on-page-number-change="pageNumberChange"
            @on-page-size-change="pageSizeChange"
          />
        </div>
      </v-card-text>

      <DialogChainsOrChannels ref="dialogChainsOrChannels" />
      <DialogPerfectStoreObjectives
        ref="dialogPerfectStoreObjectives"
        :importExcelPerfectStoreObjectivesTemplatePathName="
          importExcelPerfectStoreObjectivesTemplatePathName
        "
        :row="selectedRow"
        :update_table="update_table"
        :bpoSalesTypes="bpoSalesTypes"
        :bpoSalesTypeList="bpoSalesTypeList"
      />
      <DialogSalesTypesVetable
        ref="dialogSalesTypesVetable"
        :row="selectedRow"
        :salesTypes="salesTypes"
        :salesTypesList="salesTypesList"
      />
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import PictureInput from "vue-picture-input";
import _ from "lodash";
import moment from "moment";
import DialogChainsOrChannels from "./DialogChainsOrChannels.vue";
import DialogPerfectStoreObjectives from "./DialogPerfectStoreObjectives.vue";
import DialogSalesTypesVetable from "./DialogSalesTypesVetable";

export default {
  name: "Brands",
  components: {
    PictureInput,
    DialogChainsOrChannels,
    DialogPerfectStoreObjectives,
    DialogSalesTypesVetable
  },
  data: function() {
    return {
      filterable_col_list: [
        "name",
        "status",
        "startDate",
        "domainId",
        "endDate",
        "module_info",
        "module_sales",
        "module_actions",
        "platform_photos",
        "platform_reporting",
        "platform_training",
        "parent"
      ],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        }
      },
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterCriteria: {
        status: {},
        startDate: {},
        domainId: {},
        endDate: {},
        module_info: {},
        module_sales: {},
        module_actions: {},
        platform_photos: {},
        platform_reporting: {},
        platform_training: {},
        name: {}
      },
      showEmpty: false,

      totalbrands: 0,
      brands: [],
      yn_type: [],
      bpoSalesTypes: {},
      bpoSalesTypeList: [],
      salesTypes: {},
      salesTypesList: [],
      domainNamesList: [],

      valid: true,
      requiredRules: [v => !!v || "Required"],
      selected: null,
      editedIndex: -1,
      deleteItemId: -1,
      showEdit: false,
      dialogDelete: false,
      select_startDate_menu: false,
      select_endDate_menu: false,

      value: null,

      search_parent: null,
      isParentSearching: false,
      filtered_parents: [],

      importExcelPerfectStoreObjectivesTemplatePathName: null,
      selectedRow: null
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    async search_parent(val) {
      console.log(this.selected);
      let fetch_url =
        "brands/get_parent_list?editting_id=" +
        this.selected.id +
        "&&filter_name=" +
        val;
      const resp = await ApiService.get(fetch_url);
      this.filtered_parents = resp.data;
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 || this.editedIndex === undefined
        ? this.$t("newitem")
        : this.$t("edititem");
    },
    columns() {
      return [
        {
          title: "",
          fixed: "left",
          children: [
            {
              key: "a",
              title: "ID",
              field: "id",
              align: "left",
              width: 70,
              sortBy: "ASC"
            },
            {
              key: "b",
              title: this.$t("brands.name"),
              field: "name",
              align: "left",
              width: 200,
              sortBy: "",
              filterCustom: {
                defaultVisible: false,
                render: ({ showFn, closeFn }, h) => {
                  return (
                    <div class="custom-filter">
                      <filterableAjaxCheckboxes
                        v-model={this.filterCriteria["name"]}
                        apiUrl={`brands/getfilterlist`}
                        columnName={`name`}
                        isFullText={true}
                        filterCriteria={this.filterCriteria["name"]}
                      />
                      <div class="custom-filter-operation">
                        <v-btn
                          color="blue darken-1"
                          text
                          on-click={() => this.searchCancel(closeFn, "name")}
                        >
                          {this.$t("cancel")}
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          on-click={() => this.searchConfirm(closeFn)}
                        >
                          {this.$t("Search")}
                        </v-btn>
                      </div>
                    </div>
                  );
                }
              }
            },
            {
              key: "bb",
              title: "Nombre de dominio",
              field: "domainId",
              align: "left",
              width: 200,
              sortBy: "",
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                let domainName = this.getDomainNameById(row.domainId);
                return <span>{domainName}</span>;
              },
              filterCustom: {
                defaultVisible: false,
                render: ({ showFn, closeFn }, h) => {
                  return (
                    <div class="custom-filter">
                      <filterableAjaxCheckboxes
                        v-model={this.filterCriteria["domainId"]}
                        apiUrl={`brands/getfilterlist`}
                        columnName={`domainId`}
                        isFullText={true}
                        filterCriteria={this.filterCriteria["domainId"]}
                      />
                      <div class="custom-filter-operation">
                        <v-btn
                          color="blue darken-1"
                          text
                          on-click={() =>
                            this.searchCancel(closeFn, "domainId")
                          }
                        >
                          {this.$t("cancel")}
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          on-click={() => this.searchConfirm(closeFn)}
                        >
                          {this.$t("Search")}
                        </v-btn>
                      </div>
                    </div>
                  );
                }
              }
            }
          ]
        },
        {
          title: "",
          children: [
            {
              key: "c",
              title: this.$t("brands.logo"),
              field: "logo",
              align: "left",
              width: 200,
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                if (row.static !== null) {
                  let thumbnail_url = this.getThumbnailUri(row.static);
                  return (
                    <img
                      style="border: 1px solid #aaa; max-width: 100%;"
                      src={thumbnail_url}
                      alt=""
                    />
                  );
                } else {
                  return <span>&nbsp;</span>;
                }
              }
            },
            // { key: "d", title: this.$t("brands.description"), field: "description", align: "left", width: 250, },
            {
              key: "e",
              title: this.$t("salesforce.status"),
              field: "status",
              align: "left",
              width: 170,
              sortBy: "",
              filterCustom: {
                defaultVisible: false,
                render: ({ showFn, closeFn }, h) => {
                  return (
                    <div class="custom-filter">
                      <v-checkbox
                        v-model={this.filterCriteria["status"]["active"]}
                        label="Active"
                        value="active"
                      ></v-checkbox>
                      <v-checkbox
                        v-model={this.filterCriteria["status"]["inactive"]}
                        label="InActive"
                        value="inactive"
                      ></v-checkbox>
                      <div class="custom-filter-operation">
                        <v-btn
                          color="blue darken-1"
                          text
                          on-click={() => this.searchCancel(closeFn, "status")}
                        >
                          {this.$t("cancel")}
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          on-click={() => this.searchConfirm(closeFn)}
                        >
                          {this.$t("Search")}
                        </v-btn>
                      </div>
                    </div>
                  );
                }
              }
            },
            {
              key: "f",
              title: this.$t("brands.start_date"),
              field: "startDate",
              align: "left",
              width: 150,
              sortBy: "",
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <span>
                    {row.startDate
                      ? moment(row.startDate).format("DD/MM/YYYY")
                      : ""}
                  </span>
                );
              },
              filterCustom: {
                defaultVisible: false,
                render: ({ showFn, closeFn }, h) => {
                  return (
                    <div class="custom-filter">
                      <input
                        type="date"
                        class="filter_date_picker"
                        v-model={this.filterCriteria["startDate"]["from"]}
                        value={this.filterCriteria["startDate"]["from"]}
                        placeholder="From - "
                      />
                      <br />
                      <input
                        type="date"
                        class="filter_date_picker"
                        v-model={this.filterCriteria["startDate"]["to"]}
                        value={this.filterCriteria["startDate"]["to"]}
                        placeholder="To - "
                      />
                      <div class="custom-filter-operation">
                        <v-btn
                          color="blue darken-1"
                          text
                          on-click={() =>
                            this.searchCancel(closeFn, "startDate")
                          }
                        >
                          {this.$t("cancel")}
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          on-click={() => this.searchConfirm(closeFn)}
                        >
                          {this.$t("Search")}
                        </v-btn>
                      </div>
                    </div>
                  );
                }
              }
            },
            {
              key: "g",
              title: this.$t("brands.end_date"),
              field: "endDate",
              align: "left",
              width: 150,
              sortBy: "",
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <span>
                    {row.endDate
                      ? moment(row.endDate).format("DD/MM/YYYY")
                      : ""}
                  </span>
                );
              },
              filterCustom: {
                defaultVisible: false,
                render: ({ showFn, closeFn }, h) => {
                  return (
                    <div class="custom-filter">
                      <input
                        type="date"
                        class="filter_date_picker"
                        v-model={this.filterCriteria["endDate"]["from"]}
                        value={this.filterCriteria["endDate"]["from"]}
                        placeholder="From - "
                      />
                      <br />
                      <input
                        type="date"
                        class="filter_date_picker"
                        v-model={this.filterCriteria["endDate"]["to"]}
                        value={this.filterCriteria["endDate"]["to"]}
                        placeholder="To - "
                      />
                      <div class="custom-filter-operation">
                        <v-btn
                          color="blue darken-1"
                          text
                          on-click={() => this.searchCancel(closeFn, "endDate")}
                        >
                          {this.$t("cancel")}
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          on-click={() => this.searchConfirm(closeFn)}
                        >
                          {this.$t("Search")}
                        </v-btn>
                      </div>
                    </div>
                  );
                }
              }
            },
            {
              key: "chains",
              title: this.$t("chain"),
              field: "chains",
              align: "center",
              width: 100,
              fixed: "right",
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() =>
                        this.showDialogChainsOrChannels(row, "chain")
                      }
                    >
                      <b-icon
                        icon="box-arrow-up-right"
                        aria-hidden="true"
                      ></b-icon>
                    </v-btn>
                  </div>
                );
              }
            },
            {
              key: "channel",
              title: this.$t("channel"),
              field: "channel",
              align: "center",
              width: 100,
              fixed: "right",
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() =>
                        this.showDialogChainsOrChannels(row, "channel")
                      }
                    >
                      <b-icon
                        icon="box-arrow-up-right"
                        aria-hidden="true"
                      ></b-icon>
                    </v-btn>
                  </div>
                );
              }
            },
            {
              key: "perfectstoreobjectives",
              title: `Perfect Store Objectives`,
              field: "perfectstoreobjectives",
              align: "center",
              width: 100,
              fixed: "right",
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() =>
                        this.showDialogPerfectStoreObjectives(row)
                      }
                    >
                      <b-icon
                        icon="box-arrow-up-right"
                        aria-hidden="true"
                      ></b-icon>
                    </v-btn>
                  </div>
                );
              }
            },
            {
              key: "salesTypes",
              title: `${this.$t("brands.Sales")} ${this.$t("Types")}`,
              field: "salesTypes",
              align: "center",
              width: 100,
              fixed: "right",
              renderBodyCell: ({ row, column, rowIndex }, h) => {
                return (
                  <div>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.showDialogSalesTypes(row)}
                    >
                      <b-icon
                        icon="box-arrow-up-right"
                        aria-hidden="true"
                      ></b-icon>
                    </v-btn>
                  </div>
                );
              }
            }
          ]
        },
        {
          title: this.$t("brands.module") + this.$t("brands.available"),
          align: "center",
          children: [
            {
              key: "h",
              title: this.$t("brands.Info"),
              field: "module_info",
              align: "left",
              width: 170,
              sortBy: "",
              filterCustom: {
                defaultVisible: false,
                render: ({ showFn, closeFn }, h) => {
                  return (
                    <div class="custom-filter">
                      {this.yn_type.map(item => {
                        return (
                          <v-checkbox
                            v-model={this.filterCriteria["module_info"][item]}
                            label={item}
                            value={item}
                          ></v-checkbox>
                        );
                      })}
                      <div class="custom-filter-operation">
                        <v-btn
                          color="blue darken-1"
                          text
                          on-click={() =>
                            this.searchCancel(closeFn, "module_info")
                          }
                        >
                          {this.$t("cancel")}
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          on-click={() => this.searchConfirm(closeFn)}
                        >
                          {this.$t("Search")}
                        </v-btn>
                      </div>
                    </div>
                  );
                }
              }
            },
            {
              key: "i",
              title: this.$t("brands.Sales"),
              field: "module_sales",
              align: "left",
              width: 170,
              sortBy: "",
              filterCustom: {
                defaultVisible: false,
                render: ({ showFn, closeFn }, h) => {
                  return (
                    <div class="custom-filter">
                      {this.yn_type.map(item => {
                        return (
                          <v-checkbox
                            v-model={this.filterCriteria["module_sales"][item]}
                            label={item}
                            value={item}
                          ></v-checkbox>
                        );
                      })}
                      <div class="custom-filter-operation">
                        <v-btn
                          color="blue darken-1"
                          text
                          on-click={() =>
                            this.searchCancel(closeFn, "module_sales")
                          }
                        >
                          {this.$t("cancel")}
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          on-click={() => this.searchConfirm(closeFn)}
                        >
                          {this.$t("Search")}
                        </v-btn>
                      </div>
                    </div>
                  );
                }
              }
            },
            {
              key: "j",
              title: this.$t("brands.Actions"),
              field: "module_actions",
              align: "left",
              width: 170,
              sortBy: "",
              filterCustom: {
                defaultVisible: false,
                render: ({ showFn, closeFn }, h) => {
                  return (
                    <div class="custom-filter">
                      {this.yn_type.map(item => {
                        return (
                          <v-checkbox
                            v-model={
                              this.filterCriteria["module_actions"][item]
                            }
                            label={item}
                            value={item}
                          ></v-checkbox>
                        );
                      })}
                      <div class="custom-filter-operation">
                        <v-btn
                          color="blue darken-1"
                          text
                          on-click={() =>
                            this.searchCancel(closeFn, "module_actions")
                          }
                        >
                          {this.$t("cancel")}
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          on-click={() => this.searchConfirm(closeFn)}
                        >
                          {this.$t("Search")}
                        </v-btn>
                      </div>
                    </div>
                  );
                }
              }
            }
          ]
        },
        {
          title: this.$t("brands.platform") + this.$t("brands.access"),
          align: "center",
          children: [
            {
              key: "k",
              title: this.$t("brands.Photos"),
              field: "platform_photos",
              align: "left",
              width: 170,
              sortBy: "",
              filterCustom: {
                defaultVisible: false,
                render: ({ showFn, closeFn }, h) => {
                  return (
                    <div class="custom-filter">
                      {this.yn_type.map(item => {
                        return (
                          <v-checkbox
                            v-model={
                              this.filterCriteria["platform_photos"][item]
                            }
                            label={item}
                            value={item}
                          ></v-checkbox>
                        );
                      })}
                      <div class="custom-filter-operation">
                        <v-btn
                          color="blue darken-1"
                          text
                          on-click={() =>
                            this.searchCancel(closeFn, "platform_photos")
                          }
                        >
                          {this.$t("cancel")}
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          on-click={() => this.searchConfirm(closeFn)}
                        >
                          {this.$t("Search")}
                        </v-btn>
                      </div>
                    </div>
                  );
                }
              }
            },
            {
              key: "l",
              title: this.$t("brands.Reporting"),
              field: "platform_reporting",
              align: "left",
              width: 170,
              sortBy: "",
              filterCustom: {
                defaultVisible: false,
                render: ({ showFn, closeFn }, h) => {
                  return (
                    <div class="custom-filter">
                      {this.yn_type.map(item => {
                        return (
                          <v-checkbox
                            v-model={
                              this.filterCriteria["platform_reporting"][item]
                            }
                            label={item}
                            value={item}
                          ></v-checkbox>
                        );
                      })}
                      <div class="custom-filter-operation">
                        <v-btn
                          color="blue darken-1"
                          text
                          on-click={() =>
                            this.searchCancel(closeFn, "platform_reporting")
                          }
                        >
                          {this.$t("cancel")}
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          on-click={() => this.searchConfirm(closeFn)}
                        >
                          {this.$t("Search")}
                        </v-btn>
                      </div>
                    </div>
                  );
                }
              }
            },
            {
              key: "m",
              title: this.$t("brands.Training"),
              field: "platform_training",
              align: "left",
              width: 170,
              sortBy: "",
              filterCustom: {
                defaultVisible: false,
                render: ({ showFn, closeFn }, h) => {
                  return (
                    <div class="custom-filter">
                      {this.yn_type.map(item => {
                        return (
                          <v-checkbox
                            v-model={
                              this.filterCriteria["platform_training"][item]
                            }
                            label={item}
                            value={item}
                          ></v-checkbox>
                        );
                      })}
                      <div class="custom-filter-operation">
                        <v-btn
                          color="blue darken-1"
                          text
                          on-click={() =>
                            this.searchCancel(closeFn, "platform_training")
                          }
                        >
                          {this.$t("cancel")}
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          on-click={() => this.searchConfirm(closeFn)}
                        >
                          {this.$t("Search")}
                        </v-btn>
                      </div>
                    </div>
                  );
                }
              }
            }
          ]
        },
        {
          key: "n",
          title: this.$t("salesforce.Edit"),
          field: "actions",
          align: "center",
          width: 100,
          fixed: "right",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-icon
                  medium
                  color="primary"
                  class="mr-2"
                  on-click={() => this.onItemEdit(row)}
                >
                  mdi-pencil
                </v-icon>
              </div>
            );
          }
        }
      ];
    }
  },
  methods: {
    getDomainNameById(domainId) {
      for (let el of this.domainNamesList) {
        if (el.id === domainId) return el.name;
      }
      return null;
    },
    generateFieldSalesLabel(foundOne) {
      let rowValue = foundOne;
      if (rowValue && rowValue?.brand_perfectstore_objectives) {
        rowValue.brand_perfectstore_objectives = rowValue.brand_perfectstore_objectives.map(
          el => {
            let pushItem = { ...el };
            pushItem.bpoSalesLabel = "";
            if (Object.keys(this.bpoSalesTypes).includes(pushItem.bpoSales)) {
              pushItem.bpoSalesLabel = this.bpoSalesTypes[pushItem.bpoSales];
            }
            return pushItem;
          }
        );
      }
      return rowValue;
    },
    async update_table() {
      await this.getDataFromApi();
      if (this.selectedRow) {
        let foundOne = this.brands.find(el => el.id === this.selectedRow.id);
        foundOne = this.generateFieldSalesLabel(foundOne);
        this.selectedRow = foundOne;
      }
    },
    showDialogPerfectStoreObjectives(row) {
      this.selectedRow = row;
      if (this.selectedRow) {
        let foundOne = this.brands.find(el => el.id === this.selectedRow.id);
        foundOne = this.generateFieldSalesLabel(foundOne);
        this.selectedRow = foundOne;
      }
      this.$refs.dialogPerfectStoreObjectives.showModal(row);
    },
    showDialogSalesTypes(row) {
      this.selectedRow = row;
      console.log("this.selectedRow - ", this.selectedRow);
      this.$refs.dialogSalesTypesVetable.showModal(row);
    },
    showDialogChainsOrChannels(row, category) {
      this.$refs.dialogChainsOrChannels.showModal(row, category);
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      if (cancelFilterKey === "startDate") {
        this.filterCriteria["startDate"]["from"] = "";
        this.filterCriteria["startDate"]["to"] = "";
      } else if (cancelFilterKey === "endDate") {
        this.filterCriteria["endDate"]["from"] = "";
        this.filterCriteria["endDate"]["to"] = "";
      } else if (cancelFilterKey === "parent") {
        this.filterCriteria[cancelFilterKey] = null;
      } else {
        this.filterCriteria[cancelFilterKey] = {};
      }
      this.getDataFromApi();
    },

    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },

    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },

    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },

    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },

    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#loading-container"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },

    close() {
      this.loadingInstance.close();
    },

    getThumbnailUri(image) {
      return ApiService.getThumbnailUri(image);
    },

    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (key === "startDate" || key === "endDate") {
            let convert_value = {
              from: "",
              to: ""
            };
            if (
              value["from"] !== "" &&
              value["from"] !== null &&
              value["from"] !== undefined
            ) {
              convert_value["from"] = value["from"];
              filterModel.push({
                columnField: key,
                filterValue: convert_value
              });
            }
            if (
              value["to"] !== "" &&
              value["to"] !== null &&
              value["to"] !== undefined
            ) {
              convert_value["to"] = value["to"];
              filterModel.push({
                columnField: key,
                filterValue: convert_value
              });
            }
          } else if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value
            });
          }
        }
      }
      console.log(filterModel);
      let filter_columns = document.querySelectorAll(".filterable-column");
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },

    async getDataFromApi() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "brands";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const resp = await ApiService.get(fetch_url);
        this.brands = resp.data;
        if (this.brands.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totalbrands = resp.total;
        this.yn_type = resp.yn_type;
        this.bpoSalesTypes = resp.bpoSalesTypes;
        this.bpoSalesTypeList = resp.bpoSalesTypeList;
        this.salesTypes = resp.salesTypes;
        this.salesTypesList = resp.salesTypesList;
        this.domainNamesList = resp.domainNamesList;
        this.importExcelPerfectStoreObjectivesTemplatePathName =
          resp.importExcelPerfectStoreObjectivesTemplatePathName;
        this.close();
      } catch (error) {
        this.close();
      }
    },

    createSelectedModel(item) {
      let model = {
        id: _.get(item, "id"),
        name: _.get(item, "name"),
        description: _.get(item, "description"),
        parent: _.get(item, "parent"),
        parentId: _.get(item, "parentId"),
        domainId: _.get(item, "domainId"),
        status:
          _.get(item, "status") !== undefined
            ? _.get(item, "status")
            : "active",
        startDate: _.get(item, "startDate"),
        endDate: _.get(item, "endDate"),
        module_info:
          _.get(item, "module_info") !== undefined
            ? _.get(item, "module_info")
            : "Yes",
        module_sales:
          _.get(item, "module_sales") !== undefined
            ? _.get(item, "module_sales")
            : "Yes",
        module_actions:
          _.get(item, "module_actions") !== undefined
            ? _.get(item, "module_actions")
            : "Yes",
        platform_photos:
          _.get(item, "platform_photos") !== undefined
            ? _.get(item, "platform_photos")
            : "Yes",
        platform_reporting:
          _.get(item, "platform_reporting") !== undefined
            ? _.get(item, "platform_reporting")
            : "Yes",
        platform_training:
          _.get(item, "platform_training") !== undefined
            ? _.get(item, "platform_training")
            : "Yes",
        _extended: {
          img: null,
          static: _.get(item, "static")
        }
      };
      if (model.id === undefined && this.$refs["pictureInput"] !== undefined) {
        this.$refs["pictureInput"].removeImage();
      }
      console.log(model);
      return model;
    },

    onItemEdit(item) {
      this.selected = this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.selected._extended.img = null;
      this.showEdit = true;
      window.setTimeout(() => {
        this.$refs.pictureInput.onResize();
      }, 200); //issue discussed here: https://github.com/alessiomaffeis/vue-picture-input/issues/50#issuecomment-507013741
    },

    onPicture(img) {
      this.selected._extended.img = this.$refs["pictureInput"].file;
    },

    getImageUri(imgStatic) {
      if (imgStatic && imgStatic?.type === "file") {
        return ApiService.getFileUri(imgStatic);
      }
      return ApiService.getImageUri(imgStatic);
    },

    async onSaveClick(item) {
      if (this.$refs.form.validate()) {
        if (item.startDate > item.endDate) {
          logError(this.$t("Error") + " - " + this.$t("brands.start_date"));
          return;
        }
        let body = _.omit(item, ["_extended"]);
        let img = item._extended.img;
        if (img) {
          let { data } = await ApiService.uploadImage(img);
          logInfo("imagen subida");
          body.imageId = data.id;
        }
        console.log(body);
        if (body.id) {
          await ApiService.put(`brands/${body.id}`, body);
        } else {
          await ApiService.post(`brands`, body);
        }
        logInfo("marca actualizada");
        this.showEdit = false;
        this.getDataFromApi();
      }
    },

    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },

    onItemRemove(item_id) {
      console.log(item_id);
      this.deleteItemId = item_id;
      this.dialogDelete = true;
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.deleteItemId = -1;
      });
    },

    deleteItemConfirm() {
      if (this.deleteItemId > 0) {
        this.closeDelete();
        ApiService.delete(`brands/${this.deleteItemId}`).then(() => {
          logInfo("marca eliminada");
          this.getDataFromApi();
        });
      }
    },

    init() {
      this.getDataFromApi();
    }
  },
  async mounted() {
    this.init();
  }
};
</script>

<style>
input[type="date"]:before {
  color: lightgrey;
  content: attr(placeholder) !important;
  margin-right: 0.5em;
}
input[type="date"]:focus:before {
  content: "" !important;
}
input.filter_date_picker {
  width: 100%;
  border: 1px solid #999;
  margin-top: 10px;
}
</style>
